import { Backdrop, Button, Fade, IconButton, Modal } from "@mui/material";
import React, {
    Ref,
    forwardRef,
    useImperativeHandle,
    useState,
} from "react";
import { makeStyles } from "@mui/styles";
import Close from "@mui/icons-material/Close";
import styles from "./MembershipOfferInvalidModal.module.scss";
import { useHistory } from "react-router-dom";

export interface IMembershipOfferInvalidModalRef {
    setIsVisible: (visible: boolean) => void;
    isVisible: boolean;
}

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const MembershipOfferInvalidModal = (
    props: any,
    ref: Ref<IMembershipOfferInvalidModalRef>
) => {
    const classes = useStyles();
    const history = useHistory();

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleClose = () => {
        setIsVisible(false);
        history.push("/membership");
    };

    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );
    return (
        <Modal
            open={isVisible}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isVisible}>
                <div className={styles["membership_offer_invalid"]}>
                    <div className={styles["membership_offer_invalid-header"]}>
                        <IconButton
                            onClick={handleClose}
                            className="close-button"
                        >
                            <Close />
                        </IconButton>
                    </div>
                    <div className={styles["membership_offer_invalid-content"]}>
                        <div className={styles["membership_offer_invalid-img"]}>
                            <img
                                src="/images/membership/membership-offer-invalid.webp"
                                alt="Aviron Membership Offer"
                            />
                        </div>
                        <p className={styles["membership_offer_invalid-title"]}>
                            OOPS
                        </p>
                        <p className={styles["membership_offer_invalid-desc"]}>
                            It seem like you’re not eligible for this offer.
                        </p>
                        <div className={styles["membership_offer_invalid-btn"]}>
                            <Button
                                className={styles["btn-close"]}
                                variant="contained"
                                onClick={handleClose}
                                color="secondary"
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default forwardRef(MembershipOfferInvalidModal);
