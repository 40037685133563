import { ToggleButtonGroup, ToggleButton as ToggleButtonMui } from "@mui/material";
import style from "./ToggleGroupButton.module.scss";
import React, { ReactElement, useState } from "react";
import classNames from "classnames";

interface IOption {
  value: string | number,
  label: string | ReactElement
}
interface IToggleButton {
  options: Array<IOption>
  defaultActive?: IOption['value']
  changeToggle: any,
  name: string
}
const ToggleGroupButton = ({options, defaultActive, changeToggle, name}: IToggleButton) => {
  const [active, setActive] = useState(defaultActive);

  const handleChange = (
    newActive: number | string,
  ) => {
    if(newActive===active) {
      return;
    }
    setActive(newActive);
    changeToggle({
      value: newActive,
      name: name
    })
  };
    return (
        <div className={style["ToggleGroupButton"]}>
            {options.map((option: IOption) => {
                return (
                    <div
                        className={classNames(
                            style["switchButton"],
                            option.value === active  ? style["activeButton"] : ''
                        )}
                        onClick={() => {
                            handleChange(option?.value);
                        }}
                    >
                        {option.label}
                    </div>
                );
            })}
        </div>
    );
};

export default ToggleGroupButton;
