import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import { ENV } from "scripts/environment";
import {
    useChangeUserSubscriptionPlanMutation,
    usePlansQuery,
} from "services/webServer";

const BILLING_CYCLE = [
    {
        key: "monthly",
        interval: "monthly",
        intervalCount: 1,
        label: "Monthly",
        description: <div>$29/month</div>,
    },
    {
        key: "annual",
        interval: "yearly",
        intervalCount: 1,
        label: "Annual",
        description: (
            <>
                <div>$24/month</div>
                <div>billed annually as $288</div>
            </>
        ),
    },
    {
        key: "2-years",
        interval: "yearly",
        intervalCount: 2,
        label: "2 Year",
        description: (
            <>
                <div>$24/month</div>
                <div>billed bi-annually as $576</div>
            </>
        ),
    },
];

export function useUpgradeMembershipPlan({
    gameMembershipDetail,
    webMembershipDetail,
}: any) {
    const { data: planData, loading: loadingPlan } = usePlansQuery();
    const [billingCycle, setBillingCycle] = useState(BILLING_CYCLE[0]);
    const [isLifetimeWarrantySelected, setIsLifetimeWarrantySelected] =
        useState(false);
    const [machineCount, setMachineCount] = useState("1");
    const [changeUserSubscription] = useChangeUserSubscriptionPlanMutation();

    const isFreeMembership = [
        Number(ENV.MEMBERSHIP.FREE_6_MONTH_GAME_PLAN_ID),
    ].includes(gameMembershipDetail.planId);

    const availableBillingCycles = useMemo(() => {
        if (isFreeMembership) {
            return BILLING_CYCLE;
        }
        if (webMembershipDetail?.plan?.interval === "monthly") {
            return BILLING_CYCLE.filter((cycle) => cycle.key !== "2-years");
        }
        if (webMembershipDetail?.plan?.interval === "yearly") {
            return BILLING_CYCLE.filter((cycle) => cycle.key === "annual");
        }
        return [];
    }, [isFreeMembership, webMembershipDetail]);

    const canSelectLifetimeWarranty =
        isFreeMembership ||
        !webMembershipDetail?.plan?.productId?.includes("warranty");

    const selectedPlanProductId = canSelectLifetimeWarranty
        ? isLifetimeWarrantySelected
            ? "membership-with-warranty"
            : "membership"
        : webMembershipDetail.plan.productId;

    const plans = planData?.plans?.nodes || [];
    const newPlan = useMemo(
        () =>
            plans.find((plan) => {
                return (
                    plan.id !== webMembershipDetail.plan.id &&
                    plan.customerType ===
                        webMembershipDetail.plan.customerType &&
                    plan.interval === billingCycle.interval &&
                    plan.intervalCount === billingCycle.intervalCount &&
                    plan.maxMachines === Number(machineCount) &&
                    plan.type === "stripe" &&
                    plan.enable &&
                    plan.currency === webMembershipDetail.plan.currency &&
                    plan.productId === selectedPlanProductId &&
                    webMembershipDetail.plan.appIds.some((appId: number) => {
                        return plan.appIds?.includes(appId);
                    })
                );
            }),
        [
            plans,
            webMembershipDetail,
            billingCycle,
            machineCount,
            isLifetimeWarrantySelected,
        ]
    );

    const handleChangePlan = async () => {
        const successFunc = async function () {
            await Swal.fire(
                "Success",
                "Membership plan changed successfully.",
                "success"
            );
            // refetch();
            // setTimeout(() => {
            //     refetchReceipts({
            //         id: params.id,
            //         first: ROWS_PER_PAGE,
            //         offset: 0,
            //     });
            // }, 2000);
            window.location.reload();
        };
        const errorFunc = async (
            message: string = "Change membership plan failed"
        ) => {
            await Swal.fire("Error", message, "error");
            // refetch();
            // refetchReceipts({
            //     id: params.id,
            //     first: ROWS_PER_PAGE,
            //     offset: 0,
            // });
            window.location.reload();
        };
        try {
            if (!newPlan) {
                await Swal.fire("Error", "newPlan undefined", "error");
                return;
            }
            const { data } = await changeUserSubscription({
                variables: {
                    id: webMembershipDetail.id,
                    planId: newPlan?.id,
                },
            });
            if (
                data &&
                data.changeUserSubscriptionPlan &&
                (data as any).changeUserSubscriptionPlan.error
            ) {
                errorFunc(
                    "Can't charge your card. We will open a new tab automatically to retry payment for membership. Please reload page after payment successful."
                );
                // setState(
                //     Object.assign(state, {
                //         change_plan_invoice_url: (data as any)
                //             .changeUserSubscriptionPlan.error.invoice
                //             .hosted_invoice_url,
                //         processing_change_plan: false,
                //     })
                // );
                setTimeout(() => {
                    window.open(
                        (data as any)?.changeUserSubscriptionPlan?.error.invoice
                            .hosted_invoice_url
                    );
                }, 3000);
            } else {
                successFunc();
            }
        } catch (err) {
            errorFunc();
        } finally {
            // setState({
            //     ...state,
            //     processing_change_plan: false,
            // });
        }
    };

    const selectedUpcomingPlan =
        isFreeMembership &&
        webMembershipDetail.remark?.upcomingPlanId &&
        plans.find(
            (plan) => plan.id === webMembershipDetail.remark?.upcomingPlanId
        );

    useEffect(() => {
        // TODO: auto fill selected plan
    }, [selectedUpcomingPlan]);

    return {
        handleChangeBillingCycle: setBillingCycle,
        handleChangeMachineCount: setMachineCount,
        handleSelectLifetimeWarranty: setIsLifetimeWarrantySelected,
        billingCycle,
        isLifetimeWarrantySelected,
        machineCount,
        availableBillingCycles,
        canSelectLifetimeWarranty,
        newPlan,
        handleChangePlan,
        isFreeMembership,
        selectedUpcomingPlan,
    };
}
