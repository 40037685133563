import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useWindowSize from "Hooks/useWindowSize";
import Sidebar from "Layouts/Sidebar";
import React, { useMemo } from "react";
import { ENV } from "scripts/environment";
import { useLogoutMutation, useSharedQuery } from "services/webServer";
import { v4 as uuidV4 } from "uuid";
import CollapseMenu from "./CollapseMenu";
import style from "./header.module.scss";
import ModalChangeDevice from "./ModalChangeDevice";
import { RefModalChangeDevice } from "./ModalChangeDevice/ModalChangeDevice";
import { UserAppContext } from "contexts";
import { initialTypeMarchine } from "contexts/machines/machine.reducer";
import { handleFortmatGroupMachine } from "utils/formatGroupMachine";
import { Link } from "react-router-dom";
import { MEMBERSHIP_OFFER_KEY } from "Screens/Memberships/Main/MembershipsMain.constant";

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "0 30px",
}));

export default function MenuAppBar() {
    // const { data } = useSharedQuery();
    const { apolloClient } = React.useContext(UserAppContext);
    const [logout] = useLogoutMutation();
    const windowSize = useWindowSize();
    const refModal = React.useRef<RefModalChangeDevice>(null);
    const { machineList, userProfile } = React.useContext(UserAppContext);
    const appId = localStorage.getItem("appId");
    const defaultDevice: initialTypeMarchine = React.useMemo(() => {
        // const data = machineList.data.find(
        //     (item: initialTypeMarchine) => item.id === Number(appId)
        // );
        const data = machineList.data.find(
            (item: initialTypeMarchine) => [1,16].includes(Number(appId)) ? [1,16].includes(item.id) :  item.id === Number(appId)
        );
        return data || machineList.data[0];
    }, [machineList, appId]);
    const { name = "", group = "", image = "" } = defaultDevice || {};
    const userProfileData = userProfile?.data

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const goToHome = () => {
        window.location.href = ENV.STOREFRONT_DOMAIN;
    };
    const handleLogout = async () => {
        await logout();
        const membershipOfferKey = localStorage.getItem(MEMBERSHIP_OFFER_KEY);

        localStorage.clear();

        if (membershipOfferKey) {
            localStorage.setItem(MEMBERSHIP_OFFER_KEY, membershipOfferKey);
        }
        sessionStorage.clear();
        localStorage.setItem("appId", appId || "");
        window.location.href = `${window.location.origin}/login`;
    };

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const avatarUrl = userProfileData?.avatarUrl || "/images/user/no-avatar.png";

    const getDisplayName = () => {
        return userProfileData?.username ? `Hi, ${userProfileData?.username?.toUpperCase()}` : "Hi";
    };
    const isLoading = useMemo(()=>{
        return Boolean(userProfile?.loading)
    },[userProfile?.loading])

    return (
        <div className={style["headerWrapper"]}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position="fixed"
                    sx={{
                        boxShadow: "none",
                    }}
                    className={style["headerAppbar"]}
                >
                    <CustomToolbar
                        sx={{
                            height: {
                                xs: "76px",
                                md: "80px",
                            },
                        }}
                    >
                        {windowSize?.width <= 1250 && (
                            <div id="mobile-menu-icon">
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                    onClick={toggleDrawer}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <CollapseMenu
                                    open={openDrawer}
                                    onClose={() => {
                                        setOpenDrawer(false);
                                    }}
                                    // currentUser={data?.currentUser}
                                    currentUser={userProfileData?.username}
                                    openAccountMenu={() => {
                                        // setIsOpenAccountMenu(true);
                                    }}
                                />
                            </div>
                        )}
                        <div className={style["leftHeaderWrapper"]}>
                            <div id="aviron-logo">
                                <a href={ENV.STOREFRONT_DOMAIN}>
                                    {windowSize?.width <= 1250 ? (
                                        <img
                                            alt="aviron logo"
                                            src="https://cdn.rowaviron.com/web/logo/aviron-logo.png"
                                            width="127px"
                                            id="aviron-logo-mobile"
                                        />
                                    ) : (
                                        <img
                                            alt="aviron logo"
                                            src="https://avironactive.com/assets/icon/aviron-logo.svg"
                                            width="190px"
                                            id="aviron-logo-desktop"
                                        />
                                    )}
                                </a>
                            </div>
                            {windowSize?.width > 1250 && <Sidebar />}
                        </div>
                        <div className="avartarRight">
                            {!isLoading && (
                                <Button
                                    onClick={handleMenu}
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    sx={{
                                        textTransform: "none",
                                        display: "flex",
                                        cursor: "pointer",
                                        minWidth: "64px !important",
                                        "&:hover": {
                                            background: {
                                                md: "#efefef",
                                                xs: "transparent",
                                            },
                                            borderRadius: "80px",
                                        },
                                    }}
                                    className="avatarButtonWrapper"
                                >
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        sx={{
                                            color: "#FFF",
                                        }}
                                    >
                                        <Avatar
                                            alt="avatar"
                                            src={avatarUrl}
                                            sx={{
                                                width: {
                                                    xs: "32px",
                                                    md: "40px",
                                                },
                                                height: {
                                                    xs: "32px",
                                                    md: "40px",
                                                },
                                            }}
                                        />
                                    </IconButton>
                                    <div className="text-name-avatar">
                                        <Typography
                                            sx={{
                                                color: { xs: "#FFF", md: "#000" },
                                                display: {
                                                    xs: "none",
                                                    md: "block",
                                                },
                                                fontWeight: "600",
                                                letterSpacing: "0.03em",
                                            }}
                                        >
                                            {getDisplayName()}
                                        </Typography>
                                    </div>
                                    <img
                                        className="icon-down"
                                        src="/images/icons/chevron-down.svg"
                                        alt="icon-down"
                                    />
                                </Button>
                            )}
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                sx={{
                                    width: "300px",
                                    top: "68px",
                                }}
                            >
                                <MenuList
                                    sx={{
                                        width: "195px",
                                        // width: "183px",
                                    }}
                                >
                                    <MenuItem
                                        onClick={goToHome}
                                        sx={{
                                            fontFamily: "GRIFTER",
                                        }}
                                    >
                                        HOME
                                    </MenuItem>
                                    <Divider />
                                    <Link to="/beta_code">
                                        <MenuItem
                                            // onClick={goToHome}
                                            sx={{
                                                fontFamily: "GRIFTER",
                                            }}
                                        >
                                            BETA CODE
                                        </MenuItem>
                                    </Link>
                                    <Divider />
                                    <MenuItem
                                        onClick={handleLogout}
                                        sx={{
                                            fontFamily: "GRIFTER",
                                        }}
                                    >
                                        LOGOUT
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        sx={{
                                            fontFamily: "GRIFTER",
                                            padding: "0px 5px",
                                        }}
                                        onClick={() => {
                                            refModal.current?.setIsVisible(
                                                true
                                            );
                                            setAnchorEl(null);
                                        }}
                                    >
                                        <div className={style.device}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img
                                                    src={image}
                                                    alt="img"
                                                    style={{
                                                        width: 35,
                                                        height: 35,
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                                <div
                                                    className={
                                                        style["device-group"]
                                                    }
                                                >
                                                    <p>
                                                        {handleFortmatGroupMachine(
                                                            group
                                                        )}
                                                    </p>
                                                    <p
                                                        className={
                                                            style[
                                                            "device-group__end"
                                                            ]
                                                        }
                                                    >
                                                        {name}
                                                    </p>
                                                </div>
                                            </div>

                                            <img
                                                src="/images/icons/refresh-icon.svg"
                                                alt="switch_data"
                                            />
                                        </div>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </div>
                    </CustomToolbar>
                </AppBar>
            </Box>
            <ModalChangeDevice ref={refModal} />
        </div>
    );
}
