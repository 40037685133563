import { Backdrop, Button, Fade, IconButton, Modal } from "@mui/material";
import React, { Ref, forwardRef, useImperativeHandle, useState } from "react";
import { makeStyles } from "@mui/styles";
import Close from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { MEMBERSHIP_OFFER_KEY } from "../MembershipsMain.constant";
import styles from "./MembershipOfferClaimedModal.module.scss";

export interface IMembershipOfferClaimedModalRef {
    setIsVisible: (visible: boolean) => void;
    isVisible: boolean;
}

const useStyles = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

interface MembershipOfferClaimedModalProps {
    id: string;
}

const MembershipOfferClaimedModal = (
    { id }: MembershipOfferClaimedModalProps,
    ref: Ref<IMembershipOfferClaimedModalRef>
) => {
    const classes = useStyles();
    const history = useHistory();
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleClose = () => {
        if (id) {
            history.push(`/membership/${id}`);
            localStorage.removeItem(MEMBERSHIP_OFFER_KEY);
            return;
        }
        setIsVisible(false);
    };

    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );

    return (
        <Modal
            open={isVisible}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isVisible}>
                <div className={styles["membership_claimed"]}>
                    <div className={styles["membership_claimed-header"]}>
                        <IconButton
                            onClick={handleClose}
                            className="close-button"
                        >
                            <Close />
                        </IconButton>
                    </div>
                    <div className={styles["membership_claimed-content"]}>
                        <div className={styles["membership_claimed-img"]}>
                            <img
                                src="/images/membership/membership-claimed.webp"
                                alt="Aviron Membership Claimed"
                            />
                        </div>
                        <p className={styles["membership_claimed-title"]}>
                            OFFER CLAIMED
                        </p>
                        <p className={styles["membership_claimed-desc"]}>
                            You've snagged a sweet deal! Keep the momentum
                            going. <br />
                            We’re excited to see what you’ll achieve next!
                        </p>
                        <div className={styles["membership_claimed-btn"]}>
                            <Button
                                className={styles["btn-close"]}
                                variant="contained"
                                onClick={handleClose}
                                color="secondary"
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default forwardRef(MembershipOfferClaimedModal);
