import { Backdrop, Button, Fade, IconButton, Modal } from "@mui/material";
import React, {
    Ref,
    forwardRef,
    useContext,
    useImperativeHandle,
    useState,
} from "react";
import { makeStyles } from "@mui/styles";
import Close from "@mui/icons-material/Close";

import { useWindowSize } from "scripts/useWindowSize";
import { useHistory } from "react-router-dom";
import styles from "./MembershipOfferInvalidPaymentModal.module.scss";

export interface IMembershipOfferInvalidPaymentModalRef {
    setIsVisible: (visible: boolean) => void;
    isVisible: boolean;
}

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const MembershipOfferInvalidPaymentModal = (
    props: any,
    ref: Ref<IMembershipOfferInvalidPaymentModalRef>
) => {
    const history = useHistory();
    const classes = useStyles();
    const isDesktop = useWindowSize().width > 600;
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleClose = () => {
        setIsVisible(false);
    };

    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );
    return (
        <Modal
            open={isVisible}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isVisible}>
                <div className={styles["membership_offer_invalid"]}>
                    <div className={styles["membership_offer_invalid-header"]}>
                        <IconButton
                            onClick={handleClose}
                            className="close-button"
                        >
                            <Close />
                        </IconButton>
                    </div>
                    <div className={styles["membership_offer_invalid-content"]}>
                        <div className={styles["membership_offer_invalid-img"]}>
                            <img
                                src="/images/membership/membership-offer-invalid.webp"
                                alt="Aviron Membership Offer"
                            />
                        </div>
                        <p className={styles["membership_offer_invalid-title"]}>
                            OOPS
                        </p>
                        <p className={styles["membership_offer_invalid-desc"]}>
                            Your payment info is invalid. Please click the
                            button below to{isDesktop && <br />} update it and
                            reclaim the offer on this page.
                        </p>
                        <div className={styles["membership_offer_invalid-btn"]}>
                            <Button
                                className={styles["btn-close"]}
                                variant="contained"
                                onClick={() => {
                                    history.push("/payment_method");
                                }}
                                color="secondary"
                            >
                                UPDATE PAYMENT INFO
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default forwardRef(MembershipOfferInvalidPaymentModal);
