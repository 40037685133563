const ENV = {
    SERVER_DOMAIN: process.env.REACT_APP_SERVER_DOMAIN as string,
    STOREFRONT_DOMAIN: process.env.REACT_APP_STOREFRONT_DOMAIN as string,
    VENDURE_DOMAIN: process.env.REACT_APP_VENDURE_DOMAIN as string,
    CRM_SERVER_DOMAIN: process.env.REACT_APP_CRM_SERVER_DOMAIN as string,
    PAYMENT: {
        STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
        STRIPE_CA_PUBLIC_KEY: process.env.REACT_APP_STRIPE_CA_PUBLIC_KEY as string
    },
    STRAVA: {
        CLIENT_ID: process.env.REACT_APP_STRAVA_CLIENT_ID as string,
        REDIRECT_URL: "/strava"
    },
    LOGBOOK: {
        CLIENT_ID: process.env.REACT_APP_LOGBOOK_CLIENT_ID as string,
        URL: process.env.REACT_APP_LOGBOOK_URL as string,
        REDIRECT_URL: "/logbook"
    },
    DOMAIN: process.env.REACT_APP_CRM_URL as string,
    MEMBERSHIP: {
        FREE_6_MONTH_GAME_PLAN_ID: process.env.REACT_APP_MEMBERSHIP_FREE_6_MONTH_GAME_PLAN_ID as string,
    }
}

export {
    ENV
}
