export const membershipsColumnsForAPK = [
    {
        name: "Status",
        id: "status",
        checkStatus: true,
        className: "membershipsColumnsForAPKStatus"
    },
    {
        name: "Membership key",
        id: "token",
        className: "membershipsColumnsForAPKKey"
    },
    {
        name: "Membership type",
        id: "name",
    },
    {
        name: "Model",
        id: "model",
    },
    {
        name: "Activated",
        id: "startAt",
    },
    {
        name: "Next billing date",
        id: "nextBillingDate",
        className: "membershipsColumnsForAPKNextBillingDate"
    },
];

export const membershipsColumnsForC2 = [
    {
        name: "Status",
        id: "status",
        checkStatus: true,
    },
    {
        name: "Membership type",
        id: "name",
    },
    {
        name: "Model",
        id: "model",
    },
    {
        name: "Activated",
        id: "startAt",
    },
    {
        name: "Next billing date",
        id: "nextBillingDate",
    },
];

export const getStatus = (key: string, isPauseCollection: boolean) => {
    switch (key) {
        case "1":
            if(isPauseCollection){
                return { name: "Free Membership", color: "#16C231" }
            }
            return { name: "Activated", color: "#16C231" };
        case "2":
            return { name: "Canceled", color: "#949494" };
        case "3":
            return { name: "Paused", color: "#F58C0F" };
        case "4":
            if(isPauseCollection){
                return { name: "Pause Payment", color: "#F58C0F" }
            }
            return { name: "Not Activated", color: "#212EA9" };
        case "5":
            return { name: "Ended", color: "#6C0074" };
        case "6":
            return { name: "Past Due", color: "#B8270C" };
        default:
            return { name: "Unknown", color: "#61585c" };
    }
}

export const statusMembershipHistoryforC2 = (key: string) => {
    switch (key) {
        case "-5":
        case "-4":
        case "-1":
            return { name: "Inactive", color: "#212EA9" };
        case "1":
        case "2":
        case "3":
        case "4":
            return { name: "Active", color: "#16C231" };
        case "-3":
        case "-2":
            return { name: "Expired", color: "#c93a21" };
        case "0":
            return { name: "Unknown", color: "#61585c" };

        default:
            return { name: "Unknown", color: "#61585c" };
    }
};

export const MEMBERSHIP_OFFER_KEY = "membership-offer";
