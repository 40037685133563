import React from "react";
import { useUpgradeMembershipPlan } from "./hook";
import useGetMembershipDetail from "Hooks/MembershipHook/MembershipDetail.hook";
import { useParams } from "react-router-dom";

const MembershipUpgradePlanPage = () => {
    const params = useParams<{ id: string }>();
    const { gameMembershipDetail, loading, refetch, webMembershipDetail } =
        useGetMembershipDetail(params.id);

    return loading ? (
        <h1>Loading</h1>
    ) : (
        <UpgradeMembership
            gameMembershipDetail={gameMembershipDetail}
            webMembershipDetail={webMembershipDetail}
        />
    );
};

function UpgradeMembership({
    gameMembershipDetail,
    webMembershipDetail,
}: {
    gameMembershipDetail: any;
    webMembershipDetail: any;
}) {
    const {
        billingCycle,
        handleChangeBillingCycle,
        handleChangeMachineCount,
        handleSelectLifetimeWarranty,
        isLifetimeWarrantySelected,
        machineCount,
        availableBillingCycles,
        canSelectLifetimeWarranty,
        handleChangePlan,
        newPlan,
        isFreeMembership,
        selectedUpcomingPlan,
    } = useUpgradeMembershipPlan({
        gameMembershipDetail,
        webMembershipDetail,
    });

    return (
        <div>
            <div style={{ backgroundColor: "white", padding: 20 }}>
                <div style={{ fontSize: 30, fontWeight: "bold" }}>
                    {webMembershipDetail.plan.name}
                </div>
                {isFreeMembership && selectedUpcomingPlan && (
                    <div style={{fontSize: 15}}>{selectedUpcomingPlan?.name}</div>
                )}
            </div>
            <h1>Select billing cycle</h1>

            <div style={{ display: "flex", gap: 20, marginTop: 20 }}>
                {availableBillingCycles.map((cycle) => {
                    return (
                        <div
                            onClick={() => handleChangeBillingCycle(cycle)}
                            style={{
                                flex: 1,
                                padding: 20,
                                backgroundColor:
                                    billingCycle?.key === cycle.key
                                        ? "grey"
                                        : "white",
                            }}
                            key={cycle.key}
                        >
                            <div style={{ fontSize: 30, fontWeight: "bold" }}>
                                {cycle.label}
                            </div>
                            {cycle.description}
                        </div>
                    );
                })}
            </div>

            <h1>Add-on</h1>

            {canSelectLifetimeWarranty && (
                <div
                    style={{
                        marginTop: 20,
                        padding: 20,
                        border: "2px solid grey",
                        backgroundColor: isLifetimeWarrantySelected
                            ? "grey"
                            : "white",
                    }}
                    onClick={() =>
                        handleSelectLifetimeWarranty((prev) => !prev)
                    }
                >
                    <div>
                        [CHECKBOX]{" "}
                        {isLifetimeWarrantySelected
                            ? "CHECKED=TRUE"
                            : "CHECKED=FALSE"}
                    </div>
                    <div style={{ fontSize: 30, fontWeight: "bold" }}>
                        LIFETIME WARRANTY
                    </div>
                </div>
            )}
            <h1>Machines</h1>

            <div style={{ fontSize: 20, fontWeight: "bold" }}>
                I have{" "}
                <input
                    value={machineCount}
                    onChange={(e) => handleChangeMachineCount(e.target.value)}
                />{" "}
                machines
            </div>

            <button
                onClick={handleChangePlan}
                style={{ padding: "10px 20px", marginTop: 20, fontSize: "20" }}
            >
                {Boolean(newPlan) && <div>New: {newPlan?.name}</div>}
                <div>CONFIRM</div>
            </button>
        </div>
    );
}

export default MembershipUpgradePlanPage;
