import { Box, Grid, Paper } from "@mui/material";
import PageTitle from "Components/PageTitle";
import SuccessModal from "Components/SuccessModal";
import { Action, UserAppContext } from "contexts";
import get from "lodash/get";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { compressImage } from "scripts";
import { CONSTANTS } from "consts";
import { getCodeFromError } from "scripts/utils/handleErrors";
import { uploadApi } from "services";
import {
    useChangePasswordMutation,
    useCheckPasswordlessAccountMutation,
    useForgotPasswordMutation,
    useRemovePasswordMutation,
    useSharedQuery,
    // useUpdateUserAccountMutation,
    useRetriveRequestsQuery,
    useCheckUserJoinedQuery,
} from "services/webServer";
import Swal from "sweetalert2";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeProfileForm from "./ChangeProfileForm";
import RemovePasswordModal from "./RemovePasswordModal";
import SetPasswordModal from "./SetPasswordModal";
import UploadAvatar from "./UploadAvatar";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet";
import { AvironLoading } from "Components/Icons/AvironLoading";
import RegisterBetaAccess from "./RegisterBetaAccess";
import RegisterBetaAccessForm from "./RegisterBetaAccessForm";
import RegisterBetaSuccess from "./RegisterBetaSuccess";
import RequestBetaEnterCode from "./RequestBetaEnterCode";
import { gameServerApi } from "services/gameServerV2";
import { updateUserProfileSuccess } from "contexts/userProfile/userProfile.actions";
import useHandleActivePage from "utils/useHandleActivePage";
import { IUserProfile } from "routes/useRouteElement.hook";
import "../App.scss"
import ChangeEmailModal from "./ChangeEmailModal";
import ConfirmSetPasswordModal from "./ConfirmSetPasswordModal";
import { async } from "validate.js";

const useStyles = makeStyles(() => ({
    main: {
        maxWidth: "768px",
        marginLeft: "88px",
        ["@media (max-width:1300px)"]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
        ["@media (max-width:900px)"]: {
            maxWidth: "100%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
}));


const ProfileBasicInfo = () => {
    const initialState = {
        setPassword: false,
        removePassword: false,
        changePassword: false,
        updateProfileSuccess: false,
        changeEmail: false,
        isLoadingSetPassword: false,
    };
    useHandleActivePage(CONSTANTS.SIDEBAR_PAGEID.PROFILE);
    const classes = useStyles();
    const [steps, setSteps] = useState(0);

    const { profileDispatch, auth, userProfile, userProfileDispatch } =
        useContext(UserAppContext);

    const [modalState, setModalState] = useState(initialState);
    const [confirmSetPassword, setConfirmSetPassword] = useState(false);
    const [isPasswordLess, setPasswordLess] = useState(true);
    const [isSubmittingAvatar, setIsSubmittingAvatar] =
        useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const isAPK =
        Number(localStorage.getItem("appId")) ===
        CONSTANTS.SWITCH_MACHINE.apk.id;

    // get userinfo profile
    const { data: userData } = useSharedQuery();

    const userProfileData = userProfile?.data as IUserProfile;

    // get status beta request
    const userId = useMemo(() => auth?.id, [auth]);
    const {
        data,
        refetch,
        loading: loadingBetaRequest,
    } = useRetriveRequestsQuery({
        variables: {
            first: 1,
            offset: 0,
            userId,
        },
        skip: Boolean(!userId),
        fetchPolicy: "no-cache",
    });
    const {
        data: checkUserJoinedBetaProgram,
        refetch: refetchCheckJoined,
        loading: loadingCheckUserJoin,
    } = useCheckUserJoinedQuery({
        variables: {
            userId,
        },
        skip: Boolean(!userId),
        fetchPolicy: "no-cache",
    });

    const dataBetaRequest = useMemo(() => {
        const { betaRequests } = data || {};
        const { nodes = [] } = betaRequests || {};

        if (nodes.length === 0) return undefined;
        return nodes[0] || "";
    }, [data, data?.betaRequests?.nodes]);

    // custom mutations
    const [checkoutPasswordless] = useCheckPasswordlessAccountMutation();
    // const [updateUserAccount] = useUpdateUserAccountMutation();
    const [forgotPassword] = useForgotPasswordMutation();
    const [removePassword] = useRemovePasswordMutation();
    const [changePassword] = useChangePasswordMutation();

    const handleSetSteps = (value: number) => {
        if (value === 0) {
            refetchCheckJoined();
            refetch();
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
        setSteps(value);
    };

    // get data and check if password exist
    const checkPasswordLess = async () => {
        try {
            const { data } = await checkoutPasswordless({
                variables: {
                    email: get(userProfileData, "email"),
                },
            });
            setPasswordLess(get(data, "checkPasswordlessAccount.passwordless"));
        } catch (err) {
            Swal.fire("Error", "Check password error", "error");
        }
    };

    const handleUpdateProfileSuccess = (result: {
        dob: string;
        firstName: string;
        lastName: string;
        email: string;
        gender: number;
        weight: number;
        weightUnit: string;
        height: number;
        heightUnit: string;
        username: string;
        avatarURL: string;
    }) => {
        const {
            dob,
            firstName,
            lastName,
            email,
            gender,
            weight,
            weightUnit,
            height,
            heightUnit,
            username,
            avatarURL,
        } = result;
        const dataFormatted: IUserProfile = {
            birthDay: dob,
            firstName,
            lastName,
            email,
            gender,
            weight,
            weightUnit,
            height,
            heightUnit,
            username,
            avatarUrl: avatarURL,
        };
        userProfileDispatch(updateUserProfileSuccess(dataFormatted));
    };

    useEffect(() => {
        if (userProfileData) {
            checkPasswordLess();
        }
    }, [userProfileData]);

    const handleSubmitChangeProfile = async (values: any) => {
        const {
            firstName,
            lastName,
            height,
            height_unit,
            weight,
            weight_unit,
            year,
            month,
            day,
            gender,
            username,
        } = values || {};
        try {
            gameServerApi.user
                .updateGameUserProfile({
                    firstName,
                    lastName,
                    height: Number.parseFloat(height),
                    heightUnit: height_unit,
                    weight: Number.parseFloat(weight),
                    weightUnit: weight_unit,
                    dob: `${year}-${month}-${day}`,
                    gender: Number(gender),
                    username: username.toUpperCase(),
                })
                .then(async (result) => {
                    try {
                        await handleUpdateProfileSuccess(result.data);
                        await Swal.fire(
                            "Success",
                            "Profile updated successfully",
                            "success"
                        );
                    } catch (err) {
                        const code = getCodeFromError(err as any);
                        switch (code) {
                            case "NUNIQ":
                                Swal.fire("Error", "Username already taken", "error");
                                break;
            
                            case "UNINA":
                                Swal.fire("Error", "Invaild username", "error");
                                break;
            
                            default:
                                Swal.fire("Error", "Update user info error", "error");
                                break;
                        }
                    }
                }).catch((err: any) => {
                    // usename already exist error code
                    if(err?.response?.data?.error?.code === 4011) {
                        Swal.fire("Error", err?.response?.data?.error?.message, "error");
                        return;
                    } else {
                        Swal.fire("Error", err?.response?.data?.error?.message, "error");
                        return;
                    }
                });
        } catch (err) {
            // Will refactor this part of code later
            const code = getCodeFromError(err as any);
            switch (code) {
                case "NUNIQ":
                    Swal.fire("Error", "Username already taken", "error");
                    break;

                case "UNINA":
                    Swal.fire("Error", "Invaild username", "error");
                    break;

                default:
                    Swal.fire("Error", "Update user info error", "error");
                    break;
            }
        }
    };

    // set or reset user password
    const handleClickSetPassword = async () => {
        try {
            await forgotPassword({
                variables: {
                    email: get(userProfileData, "email"),
                },
            });
            setModalState({ ...modalState, setPassword: true });
        } catch (err) {
            Swal.fire("Error", "Set password error", "error");
        }
    };

    // remove password
    const handleRemovePassword = async () => {
        try {
            await removePassword();
            Swal.fire("Success", "Password removed successfully", "success");
            setPasswordLess(true);
            setModalState(initialState);
        } catch (err) {
            Swal.fire("Error", "Remove password error", "error");
        }
    };

    // change password
    const handleChangePassword = async (values: any) => {
        try {
            const { data } = await changePassword({
                variables: {
                    newPassword: values.newPassword,
                    oldPassword: values.oldPassword,
                },
            });
            if (data?.changePassword?.success) {
                Swal.fire(
                    "Success",
                    "Password changed successfully",
                    "success"
                );
            }
        } catch (err) {
            Swal.fire(
                "Error",
                (err as any).graphQLErrors[0]
                    ? (err as any).graphQLErrors[0].message
                    : "Remove password error",
                "error"
            );
        } finally {
            setModalState(initialState);
        }
    };

    // change user avatar
    const handleChangeAvatar = async (imgUrl: any) => {
        setIsSubmittingAvatar(true);
        try {
            const response = await fetch(imgUrl);
            const image = await response.blob();
            const compressedImage = await compressImage(image);
            const { signedUrl, url } = await uploadApi.getSignedUploadAvatarUrl(
                userData?.currentUser?.id
            );
            await uploadApi.upload(signedUrl, compressedImage);

            gameServerApi.user
                .updateGameUserProfile({
                    avatarUrl: url,
                })
                .then((result) => {
                    handleUpdateProfileSuccess(result.data);

                    setIsSubmittingAvatar(false);

                    Swal.fire(
                        "Success",
                        "Avatar changed successfully",
                        "success"
                    );
                });
        } catch (err) {
            Swal.fire("Error", "Change avatar error", "error");
        }
    };

    const handleConfirmSetPassword = async () => {
        setLoading(true);
        try {
            await forgotPassword({
                variables: {
                    email: get(userProfileData, "email"),
                },
            });
            setLoading(false);
            setConfirmSetPassword(false);
            setTimeout(()=>{
                setModalState({ ...modalState, setPassword: true });
            }, 500)
        } catch (err) {
            Swal.fire("Error", "Set password error", "error");
            setLoading(false);
        }
    }

    useEffect(() => {
        profileDispatch(
            Action.profile.setState({
                current_tab:
                    CONSTANTS.PROFILE_PAGE.CURRENT_TAB.PROFILE_BASICINFO,
            })
        );
    }, []);

    switch (steps) {
        case 1:
            return <RegisterBetaAccessForm handleSetSteps={handleSetSteps} />;
        case 2:
            return <RegisterBetaSuccess handleSetSteps={handleSetSteps} />;
        case 3:
            return <RequestBetaEnterCode handleSetSteps={handleSetSteps} />;
        case 4:
            return <RequestBetaEnterCode handleSetSteps={handleSetSteps} />;
    }

    if (userProfile.loading || loadingBetaRequest || loadingCheckUserJoin) {
        return (
            <div className="height-loading">
                <AvironLoading />
            </div>
        ) 
       
    }

    return (
        <div className="page-content profilePage">
            <div className={classes.main}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Profile | Aviron</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                {/* <Box marginBottom={{ xs: 0, md: "32px" }}>
                    <PageTitle>Profile</PageTitle>
                </Box> */}
                {isAPK && (
                    <RegisterBetaAccess
                        dataBetaRequest={dataBetaRequest}
                        handleSetSteps={handleSetSteps}
                        isJoined={
                            checkUserJoinedBetaProgram
                                ?.checkUserJoinedBetaProgram?.flagJoined
                        }
                    />
                )}

                <Paper
                    sx={{
                        padding: { xs: 0, md: "32px" },
                    }}
                >
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <UploadAvatar
                                userData={userProfile.data as IUserProfile}
                                onSubmitting={handleChangeAvatar}
                                isSubmitting={isSubmittingAvatar}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            padding={{
                                xs: "0 32px",
                                md: "0",
                            }}
                        >
                            <Box
                                mt={{
                                    xs: "0",
                                    md: "32px",
                                }}
                            >
                                <ChangeProfileForm
                                    // userData={userData}
                                    gameProfile={
                                        userProfileData as IUserProfile
                                    }
                                    isPasswordLess={isPasswordLess}
                                    onClickSetPassword={handleClickSetPassword}
                                    onClickRemovePassword={() =>
                                        setModalState({
                                            ...modalState,
                                            removePassword: true,
                                        })
                                    }
                                    onClickChangePassword={() =>
                                        setModalState({
                                            ...modalState,
                                            changePassword: true,
                                        })
                                    }
                                    onClickChangeEmail={() => {
                                        if (isPasswordLess) {
                                            setConfirmSetPassword(true)
                                        } else {
                                            setModalState({
                                                ...modalState,
                                                changeEmail: true,
                                            });
                                        }
                                    }}
                                    onSubmitting={handleSubmitChangeProfile}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <SetPasswordModal
                        open={modalState.setPassword}
                        onClose={() => setModalState(initialState)}
                    />

                    <RemovePasswordModal
                        open={modalState.removePassword}
                        onClose={() => setModalState(initialState)}
                        onClickYes={handleRemovePassword}
                    />

                    <ChangePasswordModal
                        open={modalState.changePassword}
                        onClose={() => setModalState(initialState)}
                        onClickChange={handleChangePassword}
                    />
                    <SuccessModal
                        open={modalState.updateProfileSuccess}
                        onClose={() => {
                            setModalState(initialState);
                        }}
                        message="Profile updated successfully!"
                    />
                    <ChangeEmailModal
                        open={modalState.changeEmail}
                        onClose={() => {
                            setModalState(initialState);
                        }}
                        message="Profile updated successfully!"
                        oldEmail={get(userProfileData, "email")}
                        auth={auth}
                    />
                    <ConfirmSetPasswordModal
                        open={confirmSetPassword}
                        onClose={() => {
                            setConfirmSetPassword(false)
                        }}
                        onConfirm={handleConfirmSetPassword}
                    />
                </Paper>
            </div>
            {loading && <AvironLoading />}
        </div>
    );
};
export default ProfileBasicInfo;
